import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import LayoutDefault from './layout/LayoutDefault';
import Index from './pages/Index';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LayoutDefault>
        <Router>
          <Switch>
            <Route path='/' exact>
              <Index />
            </Route>
          </Switch>
        </Router>
      </LayoutDefault>
    </QueryClientProvider>
  );
}

export default App;
