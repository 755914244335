import LayoutFooter from "../../layout/LayoutFooter";

export interface ViewerImageProps {
  value: string;
}

export default function ViewerImage({ value }: ViewerImageProps) {
  const handleClick = () => {
    navigator.share?.({
      url: value,
    });
  };

  const ShareButton = () => (
    <button
      className="hover:bg-blue-600 active:bg-blue-800 active:opacity-100 text-gray-800 active:text-gray-50 hover:text-gray-50 px-4 py-4 flex gap-2 rounded-md"
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
        />
      </svg>
      Partager
    </button>
  );

  return (
    <>
      <img
        src={value}
        className="object-contain mx-auto md:h-full h-5/6"
        alt="campagne"
      />
      {navigator.share! && (
        <div className="mb-2 mr-2 md:absolute bottom-0 right-0 flex justify-center md:justify-end">
          <ShareButton />
        </div>
      )}
    </>
  );
}
