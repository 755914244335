export default function LayoutFooter() {
  return (
    <div className="text-center w-full my-1 text-xs">
      <small className="text-gray-400">
        Campagne envoyée via{" "}
        <a className=" no-underline" href="https://sofy.fr">
          Sofy ©
        </a>
      </small>
    </div>
  );
}
