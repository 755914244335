import React, { ReactElement, ReactNode } from "react";
import LayoutFooter from "./LayoutFooter";

interface LayoutDefaultProps {
  children?: ReactNode;
}

export default function LayoutDefault({
  children,
}: LayoutDefaultProps): ReactElement {
  return (
    <div className="flex md:pb-3 flex-col flex-grow md:w-3/4 lg:w-2/3 mx-auto h-screen max-h-screen items-center justify-center">
      {children}
      <LayoutFooter />
    </div>
  );
}
