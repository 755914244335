import React from 'react';
import ViewerImage from '../components/Viewer/ViewerImage';

export default function Index() {
  let image = new URLSearchParams(window.location.search).get('image');
  if (image) {
    return <ViewerImage value={atob(image)} />;
  }
  window.location.href = 'https://sofy.fr';
  return null;
}
